import React, {
  type FC, type Dispatch, type SetStateAction, useMemo,
} from 'react';
import { useFormState } from 'react-final-form';
import { FormField } from '@/components/ui/FormElements/FormField';
import { Select } from '@/components/ui/FormElements/FormInputs/Select';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { timeZoneMapper } from '@/components/common/ManagerUserTimezone/helpers/timeZoneMapper';
import { TimezoneName } from './Timezones';

interface Props {
  timezone?: string | null;
  setTimezone: Dispatch<SetStateAction<string | undefined | null>>;
}

export const TimezoneSelector: FC<Props> = (props) => {
  const { timezone, setTimezone } = props;
  const fieldName = 'timezone';
  const { t } = useTranslation([I18N_CODES.countrySelector]);

  const { submitting } = useFormState();

  const options = useMemo(() => Object.values(TimezoneName)
    .map((tz) => {
      const label = timeZoneMapper(tz);

      return ({
        value: tz,
        label: label.replace(/_/g, ' '),
      });
    }), []);

  const initialValue = useMemo(() => (
    options.find((item) => (
      item.value === timezone
    ))
  ), [options, timezone]);

  return (
    <FormField
      label={{
        for: 'timezone-selector',
        text: t(`${I18N_CODES.countrySelector}:country_selector_timezone_input`),
      }}
      renderInput={(inputProps) => (
        <Select
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 16 }),
            menu: (provided) => ({ ...provided, zIndex: 16 }),
          }}
          menuPosition="fixed"
          isSearchable
          config={{
            validate: (option) => {
              if (option?.value) {
                setTimezone(option.value);
              }
            },
            initialValue,
          }}
          options={options}
          {...inputProps}
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
