import { SubDomains } from '@/constants/subdomains';
import { LanguageName } from '@/controllers/graphql/generated';
import { type PresentLanguageName } from '@/controllers/language/language.typedefs';

export const mainDomainLanguages: Record<string, string> = {
  [SubDomains.ua]: LanguageName.Uk,
  [SubDomains.pl]: LanguageName.Pl,
  [SubDomains.br]: LanguageName.Pt,
};

export const EXCLUDED_LANGS = [
  LanguageName.Ru,
] as const;

export const EXCLUDED_LANGS_TO_ARRAY = [
  ...EXCLUDED_LANGS,
] as LanguageName[];

export const MATE_LANG_TO_LOCALE_LANG: Record<
  PresentLanguageName,
  string
> = {
  [LanguageName.En]: 'en-US',
  [LanguageName.Uk]: 'uk-UA',
  [LanguageName.Pl]: 'pl-PL',
  [LanguageName.Pt]: 'pt-BR',
};
