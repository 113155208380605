import {
  memo,
  useMemo,
} from 'react';
import { NextSeo } from 'next-seo';
import i18Next, { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { type SeoInterface } from '@/typedefs/pageProps';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useEmployedStudentsCount } from '@/controllers/landing/hooks/useEmployedStudentsCount';
import { i18nReplaceLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';

type Props = {
  seo?: SeoInterface;
};

export const Seos = memo<Props>(({
  seo,
}) => {
  const { subDomain } = useSubDomainContext();
  const {
    asPath, pathname, locales,
  } = i18Next.useRouter();
  const { t, i18n } = useTranslation([I18N_CODES.seo, I18N_CODES.common]);

  const employedCount = useEmployedStudentsCount();

  const mainPath = removeQueryParams(asPath);

  const path = useMemo(() => {
    const basePath = i18nReplaceLanguageSubpath({
      url: mainPath || '/',
      language: '',
      supportedLanguages: locales || [DEFAULT_LOCALE],
    });

    let withoutHash = basePath.split('#')[0];

    if (!withoutHash) {
      withoutHash = '/';
    }

    return withoutHash;
  }, [locales, mainPath]);

  const titleKey = `${I18N_CODES.seo}:seo.${path}.title`;

  const descriptionKey = `${I18N_CODES.seo}:seo.${path}.description`;

  const title = useMemo(
    () => {
      const withSubdomain = `${titleKey}|${subDomain}`;

      if (i18n.exists(withSubdomain)) {
        return t(withSubdomain);
      }

      if (i18n.exists(titleKey)) {
        return t(titleKey);
      }

      if (seo?.title) {
        return seo.title;
      }

      const pageName = pathname.split('/')[1];

      return (pageName?.charAt(0).toUpperCase() ?? '') + (pageName?.slice(1) ?? '');
    },
    [i18n, pathname, seo?.title, subDomain, t, titleKey],
  );

  const description = useMemo(() => {
    const withSubdomain = `${descriptionKey}|${subDomain}`;

    if (i18n.exists(withSubdomain)) {
      return t(descriptionKey);
    }

    if (i18n.exists(descriptionKey)) {
      return t(descriptionKey, {
        employedCount,
      });
    }

    if (seo?.description) {
      return seo.description;
    }

    return '';
  }, [
    employedCount,
    descriptionKey,
    subDomain,
    i18n,
    seo?.description,
    t,
  ]);

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        description,
      }}
    />
  );
});
