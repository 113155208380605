import { type PresentLanguageName } from '@/controllers/language/language.typedefs';
import { matchesEnum } from '@/lib/helpers/functional';
import { EXCLUDED_LANGS_TO_ARRAY, mainDomainLanguages } from '@/controllers/language/language.constants';
import { LanguageName } from '../graphql/generated';

interface DomainLocaleMap {
  [key: string]: LanguageName[];
}

export const sortLanguages = (
  domainLocaleMap: DomainLocaleMap,
): DomainLocaleMap => Object.fromEntries(
  Object.entries(domainLocaleMap).map(([key, languages]) => {
    const mainLanguage = languages
      .find((language: LanguageName) => language === mainDomainLanguages[key]);

    const otherLanguages = languages
      .filter((language: LanguageName) => language !== mainLanguage);

    const sortedLanguages = mainLanguage
      ? [mainLanguage, ...otherLanguages]
      : languages;

    return [key, sortedLanguages];
  }),
);

export const isValidLanguage = (lang: string): lang is PresentLanguageName => (
  matchesEnum(LanguageName)(lang)
  && !EXCLUDED_LANGS_TO_ARRAY.includes(lang)
);
